import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RegistryRefreshService {
  refreshHolders$ = new BehaviorSubject<any>(null);

  sendMessage(message: any): void {
    this.refreshHolders$?.next(message);
  }
}
